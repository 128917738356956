import React, {Component} from 'react';
import { decorate, observable, action, isObservableObject } from 'mobx';
import { observer, inject } from 'mobx-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from "underscore";
import moment from "moment";
import ReactDom from 'react-dom';
import { TermsAndPrivacy } from '../../../utils/termsAndPrivacy';


import {
  Form,
  FormGroup,
  FormTextarea,
  FormCheckbox,
  FormSelect,
  Tooltip,
  Modal,
  ModalBody,
  ModalHeader,
  InputGroup,
  InputGroupAddon,
  FormInput,
  ButtonGroup,
  Button,
  Collapse,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, Col, Row
} from "shards-react";
import {reactLocalStorage as Storage} from "reactjs-localstorage";

import './inAppOrder.scss';

import PageTitle from "../../../components/common/PageTitle";
import FeatherIcon from 'feather-icons-react';

//inport images
import noImg from "../../../images/img/no_img.jpg";
import history from "../../../utils/history";
import {Redirect} from "react-router-dom";

const PopupDom = ({ children }) => {
  const el = document.getElementById('popupDom');
  return ReactDom.createPortal(children, el);
};


let planPd = {
  // r100: {val:'r100', name:"R-100", label: "쇼핑몰 창업 입문", price: 9900, eventPrice: 6600, eventName: "성공도약 기원 이벤트 초특가 6,600원", orderCountLte: 50, conditions:['event_5p1_first', 'event_10p2_first', 'regular']},
  r200: {val:'r200', name:"R-200", label: "월 평균 주문 수 200건 이하", price: 12900, orderCountLte: 200},
  r1000: {val:'r1000', name:"R-1000", label: "월 평균 주문 수 1000건 이하", price: 22900, orderCountLte: 1000},
  r2000: {val:'r2000', name:"R-2000", label: "월 평균 주문 수 2000건 이하", price: 52900, orderCountLte: 2000},
  r5000: {val:'r5000', name:"R-5000", label: "월 평균 주문 수 5000건 이하", price: 129000, orderCountLte: 5000}
}

let payment = {
  plan: planPd,
  payType: {
    regular: {val:'regular', label: "월 정기 결제", freePeriod: 0, period: 1, type:'autoPayment' },
  },
  planFirst: planPd,
  payTypeFirst: {
    event_5p1_first: {val:'event_5p1_first', label: "[첫 결제 이벤트] 5+1개월", freePeriod: 1, period: 6, type:'event', eventClass:'first', eventName: "첫 결제 WOW! 특별 혜택" },
    event_10p2_first: {val:'event_10p2_first', label: "[첫 결제 이벤트] 10+2개월", freePeriod: 2, period: 10, type:'event', eventClass:'first', eventName: "첫 결제 WOW! 특별 혜택" },
    regular: {val:'regular', label: "월 정기 결제", freePeriod: 0, period: 1, type:'autoPayment' },
  }
}


@inject(({ teamUser }) => ({ authCheck: teamUser.authCheck, isLogin: teamUser.isLogin, me:teamUser.me,  updateInAppOrder:teamUser.updateInAppOrder, checkOrder:teamUser.checkOrder, freeEventApply: teamUser.freeEventApply }))
@inject(({ company }) => ({ getSalesReport: company.getSalesReport }))
@inject(({ common }) => ({ getDoc: common.getDoc }))

@observer
class InAppOrder extends Component {

  //http://localhost:3000/cafeInAppOrderReturnUrl?order_id=cafe24-20240215-446642580&result=success

  //basic
  @observable store = {};
  @observable teamUser = {};
  @observable salesReport = {};
  @observable permissions = {}
  @observable company = {};
  @observable isLogin = false;
  @observable isLoading = true;
  @observable urlParams = new URLSearchParams(window.location.search);
  @observable isEventFree = false;
  //page
  @observable redirectInfo = {
    is: false,
    pathname: '/orderEnd'
  }
  @observable orderInfo = {};

  @observable payment = {
    plan: {},
    payType: {},
  };

  constructor(props) {
    super(props);
    this.init()
  }


  componentDidUpdate(prevProps, prevState){
    // console.log(prevProps)
    // console.log(prevState)
    // console.log(this.props.me)
  }

  @action
  init = async (key) => {

    if(this.props.isLogin) {
      this.isLogin = this.props.isLogin;
      this.teamUser = this.props.me;
      this.teamUser.email = this.teamUser.email ? this.teamUser.email : this.teamUser.emailId;
      this.store = this.props.me.store ? this.props.me.store : {};
      let getSalesReport = await this.props.getSalesReport({store_id: this.store._id});

      this.isUse = false;
      if(this.store.isFreeStore || this.store.isPaidUser) {
        this.isUse = true;
      }

      this.salesReport = getSalesReport.data;
      let paymentData = await this.props.getDoc('getPaymentData', { store_id:this.teamUser.selStore_id });
      this.paymentData = paymentData.data;

      // test용 this.salesReport.getMonthlySales.orderCount = 1000;
      this.store.useExpiryDateDiffNow = this.store.useExpiryDate ? moment(moment(this.store.useExpiryDate).format('YYYY-MM-DD')).diff(moment(), 'days') : 0;
      this.permissions = this.props.me.permissions;
      if(!this.store.isFreeStore) {
        this.checkOrder();
      }
      this.initOrderOptions();
      // if(this.store.useExpiryDateDiffNow < 0) {
      //   this.checkOrder();
      // }
      this.initOrderInfo();
    }
    else {

      if(Storage.get('token')) {
        //todo 재확인 - 로그인 후 해당 페이지로 넘어 오는 경우 authCheck() 함수 미샐행 할 수 있게.
        //this.props.authCheck();
      }
      // else {
      //   window.location.replace('signIn');
      // }

    }
  };

  initOrderOptions = async () => {
    if(!this.store.isUseFirstOrder) {
      this.payment = {
        plan: this.paymentData.planPd ? this.paymentData.planPd : payment.plan,
        payType: this.paymentData.payTypeFirst ? this.paymentData.payTypeFirst : payment.payTypeFirst,
      };
    }
    else {
      this.payment = {
        plan: this.paymentData.planPd ? this.paymentData.planPd : payment.plan,
        payType: this.paymentData.payType ? this.paymentData.payType : payment.payType,
      };
    }
  };


  initOrderInfo = async () => {
    this.orderInfo = _.pick(this.store, 'name', 'tel', 'email');
    this.orderInfo.store_id = this.store._id;
    this.orderInfo.teamUser_id = this.teamUser.id;
    this.orderInfo.storeName = this.store.name;
    this.orderInfo.userName = this.teamUser.name ? this.teamUser.name : this.store.presidentName;
  };


  checkOrder = async () => {
    let updatedStore = await this.props.checkOrder({store_id: this.store._id});
    if(updatedStore.data && updatedStore.data.useExpiryDate) {
      this.store.useExpiryDate = updatedStore.data.useExpiryDate;
      this.store.useExpiryDateDiffNow = moment(moment(this.store.useExpiryDate).format('YYYY-MM-DD')).diff(moment(), 'days')+1;
    }
    if(this.store.useExpiryDateDiffNow < -5) {
      if(this.store.useExpiryDateDiffNow > 0) {
        alert('서비스 사용 기간이 '+this.store.useExpiryDateDiffNow+'일 남았습니다.') ;
      }
      else if(this.store.useExpiryDateDiffNow > -7) {
        alert('서비스 사용 기간이 '+Math.abs(this.store.useExpiryDateDiffNow)+'일 지났습니다. 서비스 연장을 진행해 주세요.') ;
      }
      else if(this.store.useExpiryDateDiffNow > -14) {
        alert('서비스 사용 기간이 '+Math.abs(this.store.useExpiryDateDiffNow)+'일 지났습니다. 일부 기능이 제한됩니다. 정상적인 서비스 사용을 위해 연장을 진행해 주세요.') ;
      }
      else  {
        alert('서비스 사용 기간이 '+Math.abs(this.store.useExpiryDateDiffNow)+'일 지났습니다. 서비스 이용을 위해 연장을 진행해 주세요.') ;
      }
    }
  };

  // 팝업창 닫기
  closePostCode = async (event) => {
    this.isPopupOpen = false;
  }

  onChangeValue = async (event) => {
    const { name, value } = event.target;
    this.orderInfo[name] = value;
  };

  onChangeChecked = async (event, targetObj, keyArray) => {
    if(keyArray[0]) {
      targetObj[keyArray[0]] = !targetObj[keyArray[0]];
    }
  };

  onSelectedPayType = async (value) => {
    this.onChangePaySelect({target:{name:'payType', value:value}})
  };

  onChangePaySelect = async (event) => {
    const { name, value, type } = event.target;

    if(name === 'payType') {
      if(value === 'free') {
        this.isEventFree = true;
      }
      else {
        this.isEventFree = false;
      }
    }

    if(!value || value == '') {
      this.orderInfo.orderPlanPrice = 0;
      return false;
    }

    // if( name == "payType" ) {
    //   alert('상품을 먼저 선택해 주세요111.');
    //   this.orderInfo[name] = '';
    //   return false;
    // }

    this.orderInfo[name] = value;

    if(!this.orderInfo.payment) {this.orderInfo.payment = {};}
    this.orderInfo.payment[name] = this.payment[name][value];
    if( name == "plan" || name == "payType" ) {
      if( name == "plan" ) {
        this.orderInfo.orderName = this.payment[name][value].name;
        this.orderInfo.orderPlanPrice = this.payment[name][value].eventPrice ? this.payment[name][value].eventPrice : this.payment[name][value].price;
        this.orderInfo.orderPlanOrgPrice = this.payment[name][value].orgPrice ? this.payment[name][value].orgPrice : this.payment[name][value].price;
      }
      else if(name == "payType") {
        this.orderInfo.period = this.payment[name][value].period;
        this.orderInfo.freePeriod = this.payment[name][value].freePeriod;
        this.orderInfo.type = this.payment[name][value].type;
      }

      if(this.orderInfo.orderPlanPrice && this.orderInfo.period) {
        this.orderInfo.orderOrgAmount = this.orderInfo.orderPlanOrgPrice * this.orderInfo.period;
        this.orderInfo.orderOrgAmountKrw = this.orderInfo.orderOrgAmount.toLocaleString('ko-KR');

        this.orderInfo.orderAmount = this.orderInfo.orderPlanPrice * (this.orderInfo.period - this.orderInfo.freePeriod);
        this.orderInfo.orderAmountKrw = this.orderInfo.orderAmount.toLocaleString('ko-KR');

        this.orderInfo.orderSaleAmount = this.orderInfo.orderOrgAmount - this.orderInfo.orderAmount;
        this.orderInfo.orderSaleAmountKrw = this.orderInfo.orderSaleAmount.toLocaleString('ko-KR');

        if(!this.store.isUseFirstOrder) {
          this.orderInfo.startMoment = moment();
          this.orderInfo.endDateMoment = moment(this.orderInfo.startDate).add(this.orderInfo.period, 'months').subtract(1, 'days');
        }
        else {
          this.orderInfo.startMoment = moment();
          this.orderInfo.endDateMoment = moment(this.orderInfo.startDate).add(this.orderInfo.period, 'months').subtract(1, 'days')
        }
        this.orderInfo.startDate = this.orderInfo.startMoment.format('YYYY-MM-DD');
        this.orderInfo.endDate = this.orderInfo.endDateMoment.format('YYYY-MM-DD');
        this.orderInfo.startDateKr = this.orderInfo.startMoment.format('YYYY년 MM월 DD일');
        this.orderInfo.endDateKr = this.orderInfo.endDateMoment.format('YYYY년 MM월 DD일');

        if(this.orderInfo.type === 'autoPayment') {
          this.orderInfo.automaticPayment = 'T';
          this.orderInfo.nextPayDate = moment(this.orderInfo.startDate).add(1, 'months').format('YYYY-MM-DD');
        }
        else {
          this.orderInfo.automaticPayment = 'F';
        }


      }
      else if(!this.store.isUseFirstOrder && this.orderInfo.payType === 'free') {
        this.orderInfo.startMoment = moment();
        this.orderInfo.endDateMoment = moment(this.orderInfo.startDate).add(1, 'months').subtract(1, 'days');
        this.orderInfo.startDateKr = this.orderInfo.startMoment.format('YYYY년 MM월 DD일');
        this.orderInfo.endDateKr = this.orderInfo.endDateMoment.format('YYYY년 MM월 DD일');
      }

      console.log(this.orderInfo)

    }

  };

  onSubmit = async (event) => {
    let _this = this;
    event.preventDefault();
    if(!this.orderInfo.plan) {
      alert('상품을 선택해 주세요.');
      return false;
    }
    if(!this.orderInfo.payment) {
      alert('정기/이벤트 결제를 선택해 주세요.');
      return false;
    }
    else if(!this.orderInfo.privateAgree) {
      alert('서비스 이용약관을 동의해 주세요.');
      return false;
    }
    else if(!this.orderInfo.privateAgree) {
      alert('개인정보 이용약관을 동의해 주세요.');
      return false;
    }

    this.props.updateInAppOrder(this.orderInfo, function(res) {

      if(res.errors && res.errors.length > 0) {
        if(res.errors[0].message == 'fail password') {

        }
        else {
          alert(res.errors[0].message);
        }
      }
      else {
        let name = 'onreview';
        let specs = 'menubar=no,status=no,toolbar=no';
        let url = res.data.confirmation_url;
        let orderWindow = window.open(url, name, specs);

        let timeAfter = 0;
        let iVOrderCheck = setInterval(function() {
          timeAfter = timeAfter+2;
          let isInAppOrderCp = Storage.get('isInAppOrderCp');
          if(isInAppOrderCp == 'end' || timeAfter > 600) {
            clearInterval(iVOrderCheck);
            Storage.remove('isInAppOrderCp');
            _this.props.history.push('/inAppOrderList');
          }
        }, 2000);


        //cafeInAppOrderReturnUrl?order_id=cafe24-20230913-446425016&result=fail
      }

    });

  };

  onFreeSubmit = async (event) => {
    let _this = this;
    if(!this.orderInfo.privateAgree) {
      alert('서비스 이용약관을 동의해 주세요.');
      return false;
    }
    else if(!this.orderInfo.privateAgree) {
      alert('개인정보 이용약관을 동의해 주세요.');
      return false;
    }
    else {
      let cf = window.confirm('무료한달 이용하기를 진행 하시겠습니까?');
      if(cf) {
        this.props.freeEventApply(this.orderInfo, function(res) {
          console.log(res.data)
          if(res.data && res.data.endDate) {
            toast('서비스 신청이 완료되었습니다');
            _this.redirectInfo.is = true;
          }
          else {
            toast('서비스 신청이 정상적으로 처리되지 않았습니다.');
          }
        });
      }
      else {
        return false;
      }
    }
  };


  render() {

    return (
        <>
        {this.redirectInfo.is ? (
              <Redirect to={{pathname: this.redirectInfo.pathname}} />
          ) : (
              <>

                <div className="main-content">
                  <PageTitle />

                  {( this.isLogin && this.teamUser.emailId ) ?
                      <div className="basic-info-box">
                        <form onSubmit={e => this.onSubmit(e)}>

                          <div className="section-box basic-info-table" id="inAppOrder">
                            <div className="section-tit">
                              <h3>주문서</h3>
                            </div>
                            {/*<div className="more-produce-view"><a href="https://onnomads.com/v2/onreview.html"*/}
                            {/*                                      target="_blank">상품 설명 보기</a></div>*/}
                            <Row>
                              <Col>
                                <table>
                                  <colgroup>
                                    <col width=""/>
                                    <col width="80%"/>
                                  </colgroup>
                                  <tbody>
                                  <tr className="info-id">
                                    <th>쇼핑몰 이름</th>
                                    <td>
                                      <input name="storeName" type="text" className="table-input-text"
                                             defaultValue={this.orderInfo.storeName} disabled={true}/>
                                    </td>
                                  </tr>
                                  <tr className="info-id">
                                    <th>담당자 이름</th>
                                    <td>
                                      <input name="userName" type="text" className="table-input-text"
                                             defaultValue={this.orderInfo.userName}
                                             onChange={e => this.onChangeValue(e)}/>
                                    </td>
                                  </tr>
                                  <tr className="info-phone">
                                    <th>Tel</th>
                                    <td>
                                      <input name="tel" type="text" className="table-input-text"
                                             defaultValue={this.orderInfo.tel} onChange={e => this.onChangeValue(e)}/>
                                      <p className="guide-text">온리플 서비스 이용 관련 안내를 받아 보게 될 관리자 전화번호입니다.</p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>이메일</th>
                                    <td>
                                      <input name="email" type="text" className="table-input-text"
                                             defaultValue={this.orderInfo.email} onChange={e => this.onChangeValue(e)}/>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>서비스 이용약관</th>
                                    <td>
                                      <div className={"terms-privacy"}
                                           dangerouslySetInnerHTML={{__html: TermsAndPrivacy.termsOfService}}></div>
                                      <FormGroup className="float-right serviceAgreeCheck">
                                        <FormCheckbox name="serviceAgree" toggle
                                                      checked={this.orderInfo.serviceAgree || false}
                                                      onChange={event => this.onChangeChecked(event, this.orderInfo, ['serviceAgree'])}>
                                          유료서비스 이용약관 동의
                                        </FormCheckbox>
                                      </FormGroup>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>개인정보 이용약관</th>
                                    <td>
                                      <div className={"terms-privacy"}
                                           dangerouslySetInnerHTML={{__html: TermsAndPrivacy.privacyPolicy}}></div>

                                      <FormGroup className="float-right serviceAgreeCheck">
                                        <FormCheckbox name="privateAgree" toggle
                                                      checked={this.orderInfo.privateAgree || false}
                                                      onChange={event => this.onChangeChecked(event, this.orderInfo, ['privateAgree'])}>
                                          개인정보 이용약관
                                        </FormCheckbox>
                                      </FormGroup>
                                    </td>
                                  </tr>
                                  {/*<tr>
                                    <th>서비스 이용약관</th>
                                    <td>
                                      <FormGroup>
                                        <FormTextarea className="serviceAgree" name="serviceAgree"
                                                      defaultValue={this.rule.serviceAgree ? this.rule.serviceAgree : ''}
                                                      readOnly={true} style={{height: '350px'}}/>
                                        <button onClick={e => this.updateReviewContent(item)} type="button" className="btn-review-content-modify">수정</button>
                                      </FormGroup>

                                    </td>
                                  </tr>
                                  <tr>
                                    <th>개인정보 이용약관</th>
                                    <td>
                                      <FormGroup>
                                        <FormTextarea className="serviceAgree" name="privateAgree"
                                                      defaultValue={this.rule.privateAgree ? this.rule.privateAgree : ''}
                                                      readOnly={true} style={{height: '350px'}}/>
                                        <button onClick={e => this.updateReviewContent(item)} type="button" className="btn-review-content-modify">수정</button>
                                      </FormGroup>

                                    </td>
                                  </tr>*/}
                                  </tbody>
                                </table>
                              </Col>
                              <Col>
                                <div className="section-tit">
                                  <h3>결제</h3>
                                  {!this.store.isUseFirstOrder ? (<div>
                                  처음 주문은 더 특별한 혜택을 제공해 드립니다. 최대 10+2개월 이벤트를 첫 주문에서 이용해 보세요.
                                  </div>) : null}

                                  {!this.store.isFreeStore && this.store.useExpiryDateDiffNow < 0 ? (
                                      <p>사용기간이 {Math.abs(this.store.useExpiryDateDiffNow)}일 지났습니다. <br/>
                                        서비스 종료 {moment(this.store.useExpiryDate).format('YYYY년 MM월 DD일')} 시점으로 14일
                                        경과시({moment(this.store.useExpiryDate).add(14, 'days').format('YYYY년 MM월 DD일')})
                                        서비스 정지 및 데이터가 삭제됩니다.
                                      </p>
                                  ) : null}
                                  {this.salesReport.getMonthlySales ? (
                                      <div>
                                        쇼핑몰의
                                        주문수는 {moment(this.salesReport.getMonthlySales.start_date).format('YYYY년 MM월 DD일')}부터 {moment(this.salesReport.getMonthlySales.end_date).format('YYYY년 MM월 DD일')}까지 {this.salesReport.getMonthlySales.orderCount}개
                                        입니다. {this.store.isFreeStore ? (<p>주문 수 10개까지는 무료로 이용하실 수 있습니다. 단, AI 기능 및 일부 특정 기능은 제한이 있을 수 있습니다.)</p>) : null}
                                      </div>
                                  ) : null}
                                  {this.store.isPaidUser && false ? (<p>다음 결제일 : {moment(this.store.useExpiryDate).format('YYYY년 MM월 DD일')}</p>) : null}
                                </div>
                                <table>
                                  <tbody>
                                  <tr className="info-id">
                                    <th>상품 선택</th>
                                    <td>
                                      <FormSelect name="plan" onChange={event => {
                                        this.onChangePaySelect(event)
                                      }} value={this.orderInfo.plan}>
                                        <option value="">-상품 선택하기-</option>
                                        {this.payment && this.payment.plan && this.salesReport && this.salesReport.getMonthlySales ?
                                            Object.keys(this.payment.plan).map((key, i) => {
                                                  return this.payment.plan[key].orderCountLte >= this.salesReport.getMonthlySales.orderCount ?
                                                      (<option key={i}
                                                               value={key}>{this.payment.plan[key].label}{this.payment.plan[key].eventName ? ' - ' + this.payment.plan[key].eventName : null}</option>) : null
                                                }
                                            )
                                            : null
                                        }
                                      </FormSelect>
                                    </td>
                                  </tr>

                                  <tr className="info-id">
                                    <th>정기/이벤트 결제 선택</th>
                                    <td>
                                      <FormSelect name="payType" onChange={event => {
                                        this.onChangePaySelect(event)
                                      }} value={this.orderInfo.payType}>
                                        <option value="">-기간 선택하기-</option>
                                        {this.payment && this.payment.payType ?
                                            Object.keys(this.payment.payType).map((key, i) => {
                                              if (this.orderInfo.plan && this.payment.plan[this.orderInfo.plan].conditions && this.payment.plan[this.orderInfo.plan].conditions.length > 0) {
                                                if (this.payment.plan[this.orderInfo.plan].conditions.indexOf(key) > -1) {
                                                  return (<option key={i}
                                                                  value={key}>{this.payment.payType[key].label}</option>)
                                                }
                                              } else {
                                                return (<option key={i}
                                                                value={key}>{this.payment.payType[key].label}</option>)
                                              }

                                            })
                                            : null
                                        }
                                      </FormSelect>
                                    </td>
                                  </tr>

                                  </tbody>
                                </table>
                                {!this.isEventFree ? (
                                    <>
                                      <h3 className="mt-5">결제 내용</h3>
                                      {this.orderInfo && this.orderInfo.orderAmount > 0 ? (
                                          <table className="mt-3">
                                            <tbody>
                                            <tr>
                                              <th>결제 상품</th>
                                              <td>{this.orderInfo.payment.plan.name} - {this.orderInfo.payment.payType.label}</td>
                                            </tr>
                                            {this.payment.plan[this.orderInfo.plan].eventName ? (
                                                <tr>
                                                  <th>이벤트</th>
                                                  <td>
                                                    <strong>{this.payment.plan[this.orderInfo.plan].eventName}{this.payment.payType[this.orderInfo.payType].eventName ? ' + ' + this.payment.payType[this.orderInfo.payType].eventName : null}</strong>
                                                  </td>
                                                </tr>
                                            ) : null}
                                            <tr>
                                              <th>이용 기간</th>
                                              <td>{this.orderInfo.startDateKr + " ~ " + this.orderInfo.endDateKr}</td>
                                            </tr>
                                            {this.orderInfo.automaticPayment === 'T' ? (
                                                <tr>
                                                  <th>다음 결제일</th>
                                                  <td>{this.orderInfo.nextPayDate}</td>
                                                </tr>
                                            ) : null
                                            }
                                            <tr>
                                              <th>결제 금액(부가세 별도)</th>
                                              <td>
                                                <p><span className="order-org-amount">{this.orderInfo.orderOrgAmountKrw}원</span>&nbsp;->&nbsp;<span className="order-amount">{this.orderInfo.orderAmountKrw} 원</span></p>
                                                <p className="event-info-text">"이벤트 기간 동안 결제하시면<br />
                                                  <strong>{parseInt(this.orderInfo.orderSaleAmount / this.orderInfo.orderOrgAmount * 100)}% 할인</strong>된 가격으로&nbsp;&nbsp;
                                                  {this.orderInfo.automaticPayment === 'T' ? (
                                                      <span>월 마다&nbsp;&nbsp;</span>
                                                  ) : null
                                                  }
                                                  <strong>{this.orderInfo.orderSaleAmountKrw}원 할인</strong> 혜택을 받으실 수 있습니다."<br />
                                                  <i>"지금 바로 할인 혜택을 누리세요!"</i></p>
                                              </td>
                                            </tr>
                                            </tbody>
                                          </table>
                                      ) : (
                                          <p className="order-info-pbox">상품과 결제를 선택해 주세요.</p>
                                      )}
                                    </>) : (<>
                                  <table className="mt-3">
                                    <tbody>
                                    <tr>
                                      <th>이용 기간</th>
                                      <td>{this.orderInfo.startDateKr + " ~ " + this.orderInfo.endDateKr}</td>
                                    </tr>
                                    </tbody>
                                  </table>
                                  <ul>
                                    <li>첫 2개월 무료로 이용시 첫 결제 이벤트가 사라집니다. 아래 텍스를 클릭하셔서 혜택만이라도 확인해 보세요~</li>
                                    <li onClick={event => this.onSelectedPayType('event_6p6_first')}>6개월의 무료 혜택을 받고
                                      싶으면...
                                    </li>
                                  </ul>
                                </>)}
                              </Col>
                            </Row>
                          </div>

                          <div className="btn-save">
                            {this.isEventFree ? (
                                <input type="button" onClick={event => this.onFreeSubmit()} value={"무료한달 이용하기"}
                                       className="save-button"/>
                            ) : (
                                <>
                                  {!this.isEventFree && this.orderInfo.payment && this.orderInfo.payment.plan && this.orderInfo.payment.payType ? (
                                          <input type="button" onClick={event => this.onSubmit(event)}
                                                 value={this.orderInfo.orderSaleAmount > 0 ? this.orderInfo.orderSaleAmountKrw + "원 할인 받고 결제하기" : "결제하기"}
                                                 className="save-button"/>) :
                                      <input type="button" value={"주문서 항목을 모두 작성, 선택해 주세요."} disabled={true}
                                             className="save-button disabled"/>}
                                </>
                            )}

                          </div>
                        </form>
                      </div>
                      :
                      <p className='empty'><a href='/signin'>로그인하세요.</a></p>
                  }

                </div>


              </>)}
        </>
    )
  }
}

export default InAppOrder;
